import React from "react";
import "./App.css";
import constructionImage from "./21004063-removebg-preview.png";

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

function Header() {
  return <header className="App-header">{<h1>Extrnl.io</h1>}</header>;
}

function Main() {
  return (
    <main className="App-main">
      <h2>Stay Tuned!</h2>
      <p>
        <img
          src={constructionImage}
          alt="Under construction"
          className="construction-image"
        />
        <br />
        <span style={{ color: "white" }}>
          Our website is under construction and will be live soon. Thank you for
          your patience!
        </span>
      </p>
    </main>
  );
}

function Footer() {
  return (
    <footer className="App-footer">
      <p>&copy; 2024 Upcoming Website. All rights reserved.</p>
    </footer>
  );
}

export default App;
